import React from 'react'
class Salesforce extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            response: '',
            errorMessage: '',
            token: '',
            env: ''
        }
    }

    async componentDidMount() {
       
        const query = new URLSearchParams(window.location.search);
        const queryString = query.get('sfRequestId')

        var env = '';        

        try {
            const envresponse = await fetch(`/home/GetEnv`);
            if (!envresponse.ok) {
                throw Error(envresponse.statusText);
            }
            const envJson = await envresponse.json();
            env = envJson.env;
           
        } catch (error) {
            console.log(error);
        }

        console.log(queryString);
        if (isNaN(queryString)) {
            //this.setState({ token: queryString })
            this.state.token = queryString;
            var newClientUrl = 'https://accounts' + env + '.kestrafinancial.com/Client/EditProfile/';
            var existingClientUrl = 'https://accounts' + env + '.kestrafinancial.com/Client/EditWithPreviousChanges/';
            var url = '';
            var crmContactId = '';
            console.log("Entered api");
            fetch(`/api/salesforce?sfRequestId=${this.state.token}`)
                .then(response => response.json().then(body => ({ response, body })))
                .then(({ response, body }) => {
                    if (response.ok) {
                        var ClientId = body.ClientId;
                        var IsExistingClient = body.IsExistingClient;
                        if (body.IsExistingClient == false)
                            url = newClientUrl + ClientId;
                        else
                            url = existingClientUrl + ClientId + "?repClientNumber=" + body.ClientNumber;

                        crmContactId = body.CRMContactId;
                        if (ClientId == 0) {
                            this.setState({ errorMessage: "Sync Failed" });
                            return;
                        }
                        console.log(url);
                        window.open(url, '_self');
                    }
                })
                .catch(err => {
                    this.setState({ errorMessage: err.message });
                });
        }

        else {
            this.setState({ errorMessage: '404 error : not found' });
        }

       
    }
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    {!this.state.errorMessage && <p>
                        SYNC IN PROGRESS - YOU WILL BE REDIRECTED TO ADVISORCOMPLETE MOMENTARILY
                        </p>}
                    {!this.state.errorMessage && <img src="kestra-logo.gif" />}

                    {/* {this.state.errorMessage && <h3 className="error"> {this.state.errorMessage} </h3>} */}

                    {this.state.errorMessage &&
                        <div>
                            <div className="warning">
                                <p>Oops, something went wrong! The data sync failed unexpectedly.</p><br></br>
                                <p>Please close the window, or click the button below to go back.</p>
                            </div>                           
                        </div>
                    }
                </header>
            </div>
        );
    }
}
export default Salesforce
