import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Routes,
    Route} from "react-router-dom";
import Redtail from "./redtail"
import Salesforce from "./salesforce"

class App extends React.Component {

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <Router>
                        <Routes>
                            <Route path="/RequestProcessor" element = { <Redtail /> } /> 
                            <Route path="/home" element= { <Redtail /> } />
                            <Route path="/PostRequestProcessor" element = { <Salesforce /> } />
                        </Routes>
                    </Router >
                </header>
            </div>
        );
    }
}

export default App;