import React from 'react'
class Redtail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            response: '',
            errorMessage: '',
            token: '',
            env: ''
        }
    }

    async componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        const queryString = query.get('rtRequestId')

        var env = '';

        try {
            const envresponse = await fetch(`/home/GetEnv`);
            if (!envresponse.ok) {
                throw Error(envresponse.statusText);
            }
            const envJson = await envresponse.json();
            env = envJson.env;
        } catch (error) {
            console.log(error);
        }

        if (isNaN(queryString)) {
            this.state.token = queryString;
            var url = 'https://acportal' + env + '.kestrafinancial.com/Pages/home?redtailsync=true';
            var crmContactId = '';
            fetch(`/api/redtail?rtrequestid=${this.state.token}`)
                .then(response => response.json().then(body => ({ response, body })))
                .then(({ response, body }) => {
                    if (response.ok) {
                        crmContactId = body.CRMContactId;
                        window.open(url, '_self');
                    }
                })
                .catch(err => {
                    this.setState({ errorMessage: err.message });
                });
        }
        else {
            this.setState({ errorMessage: '404 error : not found' });
        }
    }
    render() {
        return (           
            <div>
                {!this.state.errorMessage && <p>
                    SYNC IN PROGRESS - YOU WILL BE REDIRECTED TO ADVISORCOMPLETE MOMENTARILY
                    </p>}
                {!this.state.errorMessage && <img src="kestra-logo.gif" />}

                {/* {this.state.errorMessage && <h3 className="error"> {this.state.errorMessage} </h3>} */}

                {this.state.errorMessage &&
                    <div>
                        <div className="warning">
                            <p>Oops, something went wrong! The data sync failed unexpectedly.</p><br></br>
                            <p>Please close the window, or click the button below to go back.</p>
                        </div>
                    </div>
                }
           </div>    
        );
    }
}
export default Redtail
